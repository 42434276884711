import { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  AppBar,
  Avatar,
  Button,
  Divider,
  Grid,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Popover,
  Select,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { faIndustryWindows, faNetworkWired } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute, Outlet, redirect } from "@tanstack/react-router";
import { UnstyledLink } from "@ui/components";

import { getNetworksQueryOptions } from "@apis/queries";
import userApi from "@core/apis/user.api";
import { Network } from "@core/models";
import useNetworkStore from "@core/stores/networks.store";
import { useLocalStorage } from "@hooks";

const AvatarIcon = () => {
  const { auth } = Route.useRouteContext();
  const [avatarEl, setAvatarEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(avatarEl);
  const id = open ? "simple-popover" : undefined;

  const handleAvatarClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAvatarEl(e.currentTarget);
  };

  const handleAvatarClose = () => {
    setAvatarEl(null);
  };
  return (
    <>
      <Button aria-describedby={id} onClick={handleAvatarClick}>
        <Avatar sx={{ mx: 2 }} src={auth?.user?.picture} alt={auth?.user?.name} />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={avatarEl}
        onClose={handleAvatarClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <List disablePadding>
          <ListItem disablePadding>
            <ListItemButton>
              <UnstyledLink to="/app/profile" style={{ textDecoration: "none" }}>
                <ListItemText primary="Profile" />
              </UnstyledLink>
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <Divider />
          <ListItem disablePadding>
            <ListItemButton onClick={() => auth?.logout()}>
              <ListItemText primary="Log out" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>
    </>
  );
};

const AppLayout = () => {
  // Client State
  const theme = useTheme();
  const [, setDefaultNetwork] = useLocalStorage("defaultNetwork", {
    defaultValue: "",
  });
  const selectedNetworkId = useNetworkStore((state) => state.selectedNetworkId) || "";
  const setSelectedNetworkId = useNetworkStore((state) => state.setSelectedNetworkId);
  // Server State
  const {
    isPending: isNetworksPending,
    data: networksData,
    error: networksError,
  } = useQuery(getNetworksQueryOptions());

  // if networks 401, we might not be authed on MDSL, so we call registerUsers
  const { isLoading: userRegistrationLoading, isFetched: userRegisterFetched } = useQuery({
    queryKey: ["user"],
    queryFn: () => userApi.registerUser(),
    // @ts-expect-error response is not exist on Error
    enabled: networksError?.response?.status === 401,
  });

  const { pageTitle, pageIcon } = {
    pageTitle: "Forecast",
    pageIcon: faIndustryWindows,
  };

  useEffect(() => {
    // if the selectedNetwork changes, update the default network
    if (selectedNetworkId) {
      setDefaultNetwork({
        defaultValue: selectedNetworkId,
      });
    }
  }, [selectedNetworkId, setDefaultNetwork]);

  return (
    <>
      <AppBar
        elevation={0}
        position="static"
        sx={{
          zIndex: theme.zIndex.drawer + 1,
          backgroundColor: "primary.dark",
          transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          color: "white",
        }}
      >
        <Toolbar>
          <Grid container justifyContent="space-between" alignItems="center">
            {/* Drawer top left */}
            {/* <PageAppBar filterAffects={filterAffects} /> */}

            <Grid item xs={2}>
              {/* <FontAwesomeIcon icon={pageIcon} size="2x" />
              <Typography variant="h4" data-testid="page-title">
                {pageTitle}
              </Typography> */}
            </Grid>

            {/* page title */}
            <Grid item xs={8}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <FontAwesomeIcon icon={pageIcon} size="2x" />
                <Typography variant="h4" data-testid="page-title" ml={1}>
                  {pageTitle}
                </Typography>
              </Box>
            </Grid>

            {/* Setting top right */}
            <Grid item xs={2}>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <FontAwesomeIcon icon={faNetworkWired} size="2x" />
                {isNetworksPending ? (
                  <Typography variant="body1" sx={{ color: "white" }} p={2}>
                    Loading Networks...
                  </Typography>
                ) : (
                  <Box display="flex" alignItems="center">
                    {/* Networks Dropdown */}
                    <Select
                      labelId="network-select-label"
                      id="network-select"
                      value={selectedNetworkId}
                      onChange={(e) => setSelectedNetworkId(e.target.value)}
                      color="primary"
                      type="text"
                      sx={{ color: "white" }}
                    >
                      {networksData?.map((network: Network) => (
                        <MenuItem key={network.uid} value={network.uid}>
                          {network.displayName}
                        </MenuItem>
                      ))}
                    </Select>
                    <AvatarIcon />
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>

      {/* Sidebar Drawer Manager */}
      {/* <SidebarDrawerManager /> */}

      {/* Main/Route Content */}
      <Box
        component="main"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          color: "grey.800",
        }}
      >
        {/* Service Worker related notifications */}
        {/* <ServiceWorkerPrompt /> */}

        {/* Body */}
        <Box
          component="section"
          height="100%"
          overflow="auto"
          flexGrow={1}
          display="flex"
          flexDirection="column"
        >
          {userRegistrationLoading && <LinearProgress />}
          {(networksError && userRegisterFetched) || networksData?.length === 0 ? (
            <Box p={4}>
              <Alert severity="error">
                <AlertTitle>
                  {networksData?.length === 0 ? "Error - No networks" : networksError?.message}
                </AlertTitle>
                This likely means you dont have access to any network. Please contact your admin.
              </Alert>
            </Box>
          ) : (
            <Outlet />
          )}
        </Box>

        {/* Page specific controls & wiki */}
        {/* <SubHeader id="utf-page-controls-portal" wikiUrl={wikiUrl} /> */}
      </Box>
    </>
  );
};

export const Route = createFileRoute("/app/_layout")({
  component: () => <AppLayout />,
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: async ({ context, location }) => {
    // If the user is logged out, redirect them to the login page
    if (!context.auth?.isAuthenticated && !context.auth?.isLoading) {
      // Redirect to the login page
      throw redirect({
        to: "/login",
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }
  },
  loader: async (opts) => {
    // Fancy little piece of code that ensures the networks data is loaded before rendering the component
    try {
      const networksData =
        await opts.context.queryClient.ensureQueryData(getNetworksQueryOptions());

      // Select either the default network or the first network in the list
      const defaultNetwork = JSON.parse(
        localStorage.getItem("defaultNetwork") || "{}"
      ).defaultValue;
      const selectedNetworkId = defaultNetwork || networksData?.[0]?.uid;
      if (selectedNetworkId) {
        useNetworkStore.getState().setSelectedNetworkId(selectedNetworkId);
      }
    } catch (error) {
      console.error("Error loading networks", error);
    }
  },
});
