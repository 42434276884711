import { useState } from "react";
import { FormContainer, SelectElement, TextFieldElement, useForm } from "react-hook-form-mui";
import { Alert, Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createFileRoute, Link, useParams } from "@tanstack/react-router";

import { getGroupByIdQueryOptions } from "@apis/queries";
import userApi from "@core/apis/user.api";

const SingleGroupPage = () => {
  // Route State
  const { queryClient } = Route.useRouteContext();
  const { groupId } = useParams({ strict: false });
  const formContext = useForm<{
    userUid: string;
    username: string;
    resourceLevel: number;
    managementLevel: number;
  }>({
    defaultValues: {
      userUid: "",
      username: "",
      resourceLevel: 4,
      managementLevel: 4,
    },
  });
  const [addToGroupError, setAddToGroupError] = useState<string | null>(null);

  // Server state
  const { data: groupData } = useQuery(getGroupByIdQueryOptions(groupId));
  const addNewPolicyToGroupMutation = useMutation({
    mutationFn: async ({ name, resourceLevel }: { name: string; resourceLevel: number }) => {
      return userApi.addNewPolicyToGroup(groupId!, {
        name: name,
        resourceLevel: resourceLevel,
      });
    },
    onSettled: () => queryClient.invalidateQueries({ queryKey: ["group", groupId] }),
  });
  const addNewUserToGroupMutation = useMutation({
    mutationFn: ({
      userUid,
      username,
      resourceLevel,
      managementLevel,
    }: {
      userUid: string;
      username: string;
      resourceLevel: number;
      managementLevel: number;
    }) => {
      return userApi.addUserToGroup(groupId!, userUid, username, {
        resourceLevel,
        managementLevel,
      });
    },
    onSettled: async () => {
      // setAddToGroupError(null);
      // formContext.reset();
      await queryClient.invalidateQueries({ queryKey: ["group", groupId] });
    },
    onError: (error) => {
      console.error(error);
      if (error && error.status === 404) {
        setAddToGroupError("User not found");
      }
    },
    onSuccess: () => {
      setAddToGroupError(null);
      formContext.reset();
    },
  });

  return (
    <Box px={4} py={2}>
      <Button
        variant="contained"
        color="primary"
        startIcon={<FontAwesomeIcon icon={faChevronLeft} />}
      >
        <Link to="/app/profile/groups" style={{ color: "white", textDecoration: "none" }}>
          Back to All Groups
        </Link>
      </Button>
      <Typography variant="h2">Group: {groupData?.name}</Typography>
      <Divider />
      <Box mt={2}>
        {addToGroupError && <Alert severity="error">{addToGroupError}</Alert>}
        {addNewPolicyToGroupMutation.isError && (
          <Alert severity="error">{addNewPolicyToGroupMutation.error?.message}</Alert>
        )}
      </Box>
      <Grid container spacing={2} p={2}>
        <Grid item xs>
          <Typography variant="h3">Users in Group</Typography>
          <ul>
            {groupData?.users?.map((policy) => (
              <li key={policy.uid}>
                <Typography>{policy.name}</Typography>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item xs>
          <FormContainer
            formContext={formContext}
            onSuccess={async (data) => {
              addNewUserToGroupMutation.mutate(data);
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h3">Add User to Group</Typography>
              <Box display="flex" flexDirection="row" gap={2}>
                <TextFieldElement
                  name="username"
                  label="Username"
                  style={{ flex: 1 }}
                  rules={{
                    validate: (value, formValues) =>
                      !!value || !!formValues.userUid || "Please enter a username or UID",
                  }}
                />
                <Divider orientation="vertical" flexItem />
                <TextFieldElement
                  name="userUid"
                  label="User UID"
                  style={{ flex: 1 }}
                  rules={{
                    validate: (value, formValues) =>
                      !!value || !!formValues.username || "Please enter a username or UID",
                  }}
                />
              </Box>
              <SelectElement
                name={"resourceLevel"}
                label={"Resource Level"}
                options={[
                  { label: "0", id: 0 },
                  { label: "1", id: 1 },
                  { label: "2", id: 2 },
                  { label: "3", id: 3 },
                  { label: "4", id: 4 },
                ]}
                required
              />
              <SelectElement
                name={"managementLevel"}
                label={"Management Level"}
                options={[
                  { label: "0", id: 0 },
                  { label: "1", id: 1 },
                  { label: "2", id: 2 },
                  { label: "3", id: 3 },
                  { label: "4", id: 4 },
                ]}
                required
              />
              <Button type="submit" variant="outlined">
                Add User
              </Button>
            </Stack>
          </FormContainer>
        </Grid>
      </Grid>

      <Divider />

      <Grid container spacing={2} p={2}>
        <Grid item xs>
          <Typography variant="h3">Policies in Group</Typography>
          <ul>
            {groupData?.policies?.map((policy) => (
              <li key={policy.uid}>
                <Link to={`/app/profile/policies/${policy.uid}`}>
                  <Typography>{policy.name}</Typography>
                </Link>
              </li>
            ))}
          </ul>
        </Grid>
        <Grid item xs>
          <FormContainer
            defaultValues={{
              name: "",
              resourceLevel: 4,
            }}
            onSuccess={async (data) => {
              addNewPolicyToGroupMutation.mutate(data);
            }}
          >
            <Stack spacing={2}>
              <Typography variant="h3">Add Policy to Group</Typography>

              <TextFieldElement name="name" label="Policy Name" required />
              <SelectElement
                name={"resourceLevel"}
                label={"Resource Level"}
                options={[
                  { label: "0", id: 0 },
                  { label: "1", id: 1 },
                  { label: "2", id: 2 },
                  { label: "3", id: 3 },
                  { label: "4", id: 4 },
                ]}
                required
              />
              <Button type="submit" variant="outlined">
                Add Policy
              </Button>
            </Stack>
          </FormContainer>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  );
};

export const Route = createFileRoute("/app/_layout/profile/groups/$groupId")({
  component: () => <SingleGroupPage />,
  loader: async ({ params, context }) => {
    // if not authed, continue
    if (!context.auth?.isAuthenticated) {
      return;
    }
    const data = await context.queryClient.ensureQueryData(
      getGroupByIdQueryOptions(params.groupId)
    );
    return {
      data,
    };
  },
});
