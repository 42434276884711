import { useEffect, useRef, useState } from "react";
import {
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { faFire } from "@fortawesome/pro-light-svg-icons";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import HighchartsReact from "highcharts-react-official";
import hc_more from "highcharts/highcharts-more";
import * as Highcharts from "highcharts/highstock";
import hcBoost from "highcharts/modules/boost";
import { DateTime } from "luxon";

import ElectricityApi from "@apis/electricity.api";
import {
  getForecastsQueryOptions,
  getSLMeterDataQueryOptions,
  getSubscriptionLimitsQueryOptions,
} from "@apis/queries";
import useNetworkStore from "@core/stores/networks.store";
import { formatNumberForLocale } from "@core/utils";
import { Forecast, MeterData, SubscriptionLimit } from "@models/electricity.models";
import { BaseCard } from "@shared/ui/analytics/cards";
import UtfDateRangePicker from "@shared/ui/inputs/UtfDateRangePicker/UtfDateRangePicker";

import { FilterBar } from "./components/FilterBar";
import HeadingBar from "./components/HeadingBar";

hcBoost(Highcharts);
hc_more(Highcharts);

const SLSelect = ({
  subscriptionLimits,
  selected,
  onClick,
}: {
  subscriptionLimits: SubscriptionLimit[];
  selected: string | undefined;
  onClick: (value: string) => void;
}) => {
  if (selected) {
    return (
      <Box width={200} pl={2}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Subscription Limit</InputLabel>
          <Select
            labelId="sl-select-label"
            id="sl-select"
            value={selected}
            onChange={(e) => onClick(e.target.value)}
            color="primary"
            type="text"
            label="Subscription Limit"
            size="small"
          >
            {subscriptionLimits?.map((sl: SubscriptionLimit) => (
              <MenuItem key={sl.uid} value={sl.uid}>
                {sl.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    );
  } else {
    return <CircularProgress />;
  }
};

const App = () => {
  // Component State
  const meterChartRef = useRef<HighchartsReact.RefObject>(null);
  const [navigatorChartRef, setNavigatorChartRef] = useState();
  const [selectedSL, setSelectedSL] = useState<SubscriptionLimit | undefined>(undefined);
  const [latestForecast, setLatestForecast] = useState<Forecast>();
  const theme = useTheme();
  const [filterRange, setFilterRange] = useState<{
    start: DateTime | undefined;
    end: DateTime | undefined;
  }>({
    start: undefined,
    end: undefined,
  });
  const [fetchedRange, setFetchedRange] = useState<{
    start: DateTime | undefined;
    end: DateTime | undefined;
  }>({
    start: DateTime.local().startOf("day").minus({ days: 3 }),
    end: DateTime.local().startOf("day").plus({ days: 4 }),
  });

  // Client State
  const selectedNetworkId = useNetworkStore((state) => state.selectedNetworkId);
  const selectedSLId = useNetworkStore((state) => state.selectedSLId);
  const setSelectedSLId = useNetworkStore((state) => state.setSelectedSLId);

  /// Server State

  // SLs
  const {
    isPending,
    error,
    data: subscriptionLimits,
  } = useQuery(getSubscriptionLimitsQueryOptions(selectedNetworkId));

  // Meter Data
  const {
    isPending: isMeterDataPending,
    error: meterDataError,
    data: meterData,
    isFetched: meterDataFetched,
  } = useQuery(
    getSLMeterDataQueryOptions(
      selectedNetworkId,
      selectedSLId,
      fetchedRange.start,
      fetchedRange.end
    )
  );

  // Network Coordinates
  const { data: networkCoordinates, isFetched: networkCoordinatesFetched } = useQuery({
    queryKey: ["networkCoordinates", selectedNetworkId],
    queryFn: () => ElectricityApi.getNetworkCoordinates(selectedNetworkId),
    enabled: !!selectedNetworkId,
  });

  // Weather Data
  const { isPending: isWeatherDataPending, data: weatherData } = useQuery({
    queryKey: ["weatherData", selectedNetworkId, fetchedRange.start, fetchedRange.end],
    queryFn: () =>
      ElectricityApi.getWeatherData(
        networkCoordinates,
        fetchedRange.start?.toISO(),
        fetchedRange.end?.toISO()
      ),
    enabled: !!networkCoordinatesFetched && !!fetchedRange.start && !!fetchedRange.end,
  });

  const { isPending: isForecastsPending, data: forecasts } = useQuery(
    getForecastsQueryOptions(selectedNetworkId)
  );

  /// Fin Server State

  // If subscriptionLimits is fetched, set the first SL as selected
  useEffect(() => {
    if (subscriptionLimits && subscriptionLimits.length > 0) {
      console.log("sl limits fetched", subscriptionLimits);
      setSelectedSLId(subscriptionLimits[0].uid);
      setSelectedSL(subscriptionLimits[0]);
    }
  }, [subscriptionLimits, setSelectedSLId]);

  // If selectedSLId is updated, set the selected SL
  useEffect(() => {
    if (selectedSLId && subscriptionLimits) {
      console.log("selectedSLId updated", selectedSLId);
      const selectedSL = subscriptionLimits.find(
        (sl: SubscriptionLimit) => sl.uid === selectedSLId
      );
      setSelectedSL(selectedSL);
    }
  }, [selectedSLId, subscriptionLimits]);

  // When we fetch all forecasts, set the latest forecast based on forecast.issuedAt
  useEffect(() => {
    if (forecasts && forecasts.length > 0) {
      const latest = forecasts.reduce((prev, current) =>
        prev.issuedAt > current.issuedAt ? prev : current
      );
      setLatestForecast(latest);
    }
  }, [forecasts]);

  // if filterRange is updated, set the extremes of the chart
  useEffect(() => {
    if (filterRange.start && filterRange.end) {
      console.log("filterRange updated", filterRange);
      [meterChartRef, navigatorChartRef].forEach((chartRef) => {
        if (chartRef?.current?.chart.xAxis[0]) {
          const chart = chartRef.current?.chart.xAxis[0];
          if (chart && typeof chart.setExtremes === "function") {
            // Update the primary(MeterData) chart extremes.
            chart.setExtremes(
              filterRange.start?.toMillis(),
              filterRange.end?.toMillis(),
              true,
              false
            );
          }
        } else {
          console.warn("chart not ready yet");
        }
      });
    }
  }, [filterRange, meterChartRef, navigatorChartRef]);

  // if fetchedRange is updated, reset the filterRange
  useEffect(() => {
    if (fetchedRange.start && fetchedRange.end) {
      console.log("fetchedRange updated", fetchedRange);
      setFilterRange(fetchedRange);
    }
  }, [fetchedRange]);

  return (
    <>
      {isPending && <LinearProgress />}
      <Grid
        container
        direction="column"
        p={2}
        justifyContent="space-between"
        alignItems="stretch"
        flexGrow={1}
      >
        <Grid item xs={1}>
          <HeadingBar
            title="Stored Forecast"
            addons={[
              {
                action: (
                  <UtfDateRangePicker
                    startDate={fetchedRange.start}
                    endDate={fetchedRange.end}
                    maxDate={DateTime.local().plus({ days: 14 })}
                    minDate={DateTime.local().minus({ months: 3 })}
                    maxDuration={{
                      days: 14,
                      months: 0,
                      years: 0,
                    }}
                    onChange={(start, end) => {
                      setFetchedRange({ start, end });
                    }}
                    placeholder="Fetch Data"
                  />
                ),
              },

              {
                action: (
                  <SLSelect
                    subscriptionLimits={subscriptionLimits || []}
                    selected={selectedSLId}
                    onClick={(value) => setSelectedSLId(value)}
                  />
                ),
              },
            ]}
          />
          {error && <Typography>Error: {error.message}</Typography>}
        </Grid>
        <Grid item flexGrow={1} pb={8}>
          <BaseCard
            loading={isMeterDataPending || isForecastsPending || isWeatherDataPending}
            title="Power Consumption"
            titleAddon={
              latestForecast
                ? [
                    [
                      <Typography variant="body2" color="textSecondary" pr={3}>
                        Forecasted at{" "}
                        {latestForecast.issuedAt.toLocaleString(DateTime.DATETIME_SHORT)}
                      </Typography>,
                    ],
                    [
                      <Tooltip
                        title={`${latestForecast.issuedAt.plus({ hours: 6 }).toLocaleString(DateTime.DATETIME_SHORT)}`}
                      >
                        <Typography variant="body1" color="textSecondary">
                          Next Forecast {latestForecast.issuedAt.plus({ hours: 6 }).toRelative()}
                        </Typography>
                      </Tooltip>,
                    ],
                  ]
                : null
            }
          >
            <Box width="100%" height="100%" p={1}>
              {isMeterDataPending || !(fetchedRange.start || fetchedRange.end) ? (
                <CircularProgress />
              ) : null}
              {meterDataError && (
                <Typography color="error">Error: {meterDataError.message}</Typography>
              )}
              {meterData && meterData.length === 0 && <Typography>No data available</Typography>}
              {meterDataFetched && meterData && (
                <HighchartsReact
                  highcharts={Highcharts}
                  containerProps={{ style: { height: "100%", maxHeight: "100%" } }}
                  ref={meterChartRef}
                  options={{
                    chart: {
                      id: "main",
                      zooming: {
                        type: "x",
                      },
                    },
                    xAxis: {
                      type: "datetime",
                      title: {
                        text: "Time",
                      },
                      min: fetchedRange.start?.toMillis() || null,
                      max: fetchedRange.end?.toMillis() || null,
                      plotLines: [
                        {
                          value: DateTime.local().toMillis(),
                          width: 1,
                          zIndex: 5,
                          color: theme.palette.secondary.main,
                          label: {
                            useHTML: true,
                            rotation: 0,
                            verticalAlign: "top",
                            x: -16,
                            text: `<div style="background-color: ${theme.palette.secondary.main}; color: white; padding: 4px; border-radius: 5px;">Now</div>`,
                          },
                        },
                      ],
                      events: {
                        afterSetExtremes: (e: any) => {
                          const start = DateTime.fromMillis(e.min);
                          const end = DateTime.fromMillis(e.max);
                          // Update the filterRange
                          setFilterRange({ start, end });
                        },
                      },
                    },
                    yAxis: [
                      {
                        height: "50%",
                        title: {
                          text: "Power (kW)",
                        },
                        plotLines: [
                          {
                            value: selectedSL?.customerLimit,
                            width: 2,
                            zIndex: 5,
                            color: theme.palette.error.main,
                            label: {
                              useHTML: true,
                              x: 0,
                              y: -6,
                              text: `<div style="background-color: ${theme.palette.error.main}; color: white; padding: 4px; border-radius: 5px;">${selectedSL?.customerLimit} kW</div>`,
                            },
                          },
                        ],
                      },
                      {
                        top: "52%",
                        height: "48%",
                        offset: -0,
                        title: {
                          text: "Outdoor Temperature (°C)",
                        },
                      },
                    ],
                    title: {
                      text: null,
                    },
                    legend: {
                      verticalAlign: "top",
                      x: 0,
                      y: 0,
                    },
                    series: [
                      {
                        type: "spline",
                        name: "Power",
                        data: meterData.map((row: MeterData) => [
                          new Date(row.time).getTime(),
                          row.power,
                        ]),
                        tooltip: {
                          crosshairs: true,
                          valueSuffix: " kW",
                          pointFormatter: function (this: any) {
                            return `
                        <span style="color:${this.color}">\u25CF</span>
                        ${this.series.name}
                        <br/>
                        Power: <b>${formatNumberForLocale(this.y)} kW</b>
                        `;
                          },
                        },
                      },
                      {
                        type: "spline",
                        name: "Forecasted Power",
                        data: meterData.map((row: MeterData) => {
                          return {
                            x: new Date(row.time).getTime(),
                            y: row.forecastedPower,
                            upper: row.forecastedPowerUpper,
                            lower: row.forecastedPowerLower,
                          };
                        }),
                        tooltip: {
                          crosshairs: true,
                          valueSuffix: " kW",
                          pointFormatter: function (this: any) {
                            return `
                        <span style="color:${this.color}">\u25CF</span>
                        ${this.series.name}
                        <br/>
                        Power:<b> ${formatNumberForLocale(this.y)} kW</b>
                        <br/>
                        Range:<b> ${formatNumberForLocale(this.lower)} kW - ${formatNumberForLocale(this.upper)} kW</b>
                        `;
                          },
                        },
                      },
                      {
                        name: "Forecast Range",
                        data: meterData.map((row: MeterData) => [
                          new Date(row.time).getTime(),
                          row.forecastedPowerLower,
                          row.forecastedPowerUpper,
                        ]),
                        type: "arearange",
                        lineWidth: 0,
                        linkedTo: ":previous",
                        color: Highcharts.getOptions().colors![1],
                        fillOpacity: 0.3,
                        zIndex: 0,
                        marker: {
                          enabled: false,
                        },
                        enableMouseTracking: false,
                      },
                      {
                        type: "spline",
                        name: "Outdoor Temperature",
                        yAxis: 1,
                        data: weatherData
                          ? weatherData.map((row) => [new Date(row.datetime).getTime(), row.value])
                          : [],
                      },
                    ],
                  }}
                />
              )}
            </Box>
          </BaseCard>
        </Grid>
        <Grid item xs={1}>
          {meterDataFetched && fetchedRange.start && fetchedRange.end && (
            <FilterBar
              registerChart={(key, ref) => {
                setNavigatorChartRef(ref);
              }}
              seriesData={meterData}
              // @ts-expect-error Check happens at line 412
              fetchedRange={fetchedRange}
              filterRange={filterRange}
              onExtremesChange={(start, end) => {
                setFilterRange({ start, end });
              }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export const Route = createFileRoute("/app/_layout/")({
  staticData: {
    pageTitle: "App",
    pageIcon: faFire,
  },
  component: App,
});
